.footer-div1{
    background: #FFFFFF;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
}


.footer-text1{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 50px;
line-height: 75px;
/* identical to box height */
text-align: center;
color: #000000;
}

.footer-text2{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
/* identical to box height */
text-align: center;
color:#000000;
margin-top: 8px;
transform: translateX(7px);
text-decoration: underline;
}

.footer-text3{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
/* identical to box height */
text-align: center;
color: #DBDBDB;
margin-top: 12px;
}

.footer-social-div{
column-gap: 20px;
margin-top: 30px;
}

.footer-qasim-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 36px;
/* identical to box height, or 212% */
color: #6B6B6B;
margin-top: 20px;
}

@media screen and (max-width: 576px) {
    .footer-text2{
        transform: translateX(0px);
    }
    .footer-qasim-text{
        margin-top: 22px;
    }
}

