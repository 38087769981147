@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

.home-div1 {
    min-height: 100vh;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
}


.banner-hey-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */
    color: #FFDD67;
    text-align: center;
}

.banner-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;
    /* identical to box height, or 129% */
    color: #FFFFFF;
    text-align: center;

}

.banner-text1 span {
    color: #F74C66;
}

.banner-text2 {
    

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 90px;
    line-height: 110px;
    /* identical to box height */
    text-transform: uppercase;
    text-align: center;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* margin-top: 30px; */
    text-fill-color: transparent;
  
}

.banner-text2 span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 170px;
    /* identical to box height, or 79% */
    text-align: center;
    color: #DEDEDE;
    /* margin-top: 34px; */
    text-transform: uppercase;
    text-align: center;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* margin-top: 10px; */
    text-fill-color: transparent;
}

.banner-para1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */
    color: #EDEDED;
    /* margin-top: 50px; */
    text-align: center;
    margin-top: 20px;
}

.watch-banner-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    /* identical to box height */
    margin-top: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFBC00;
}

.watch-banner-text:hover {
    color: #FFBC00 !important;
    cursor: pointer;
}


/* section # 2 */

.home-work-div1 {
    padding-top: 55px;
    padding-bottom: 55px;
    background: #262626;
    position: relative;
    overflow: hidden;
    z-index: 9;
}

.work-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 34px;
    /* identical to box height */
    color: #C5C5C5;
    margin-bottom: 0px;
}

.work-text2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 46px;
    /* identical to box height */
    color: #FFBC00;
    margin-bottom: 0px;
}

.work-col-div {
    padding-left: 8px;
    padding-right: 8px;
}

.work-company-div1 {
    /* height: 80px; */
    /* border: 1px solid #454545; */
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 170px;
}

.company-logo1 {
    width: 145px !important;
    height: 65px;
}

/* .company-logo4 {
    width: 121px !important;
} */



/* section # 3 */

.portfolio-div1 {
    padding-top: 150px;
    padding-bottom: 145px;
    position: relative;
    background: white;
    z-index: 99;
    overflow: hidden;
    /* justify-content: center; */
}

.vert-line {
    position: absolute;
    left: 50%;
    /* transform:translateX(-50%); */
    width: 140px;
    height: 0px;
    bottom: -235px;
    border: 1px solid #888888;
    transform: translateX(-50%) rotate(90deg) !important;
    z-index: 999999999;
}

.portfoliopage-div1 {
    padding-top: 130px;
    padding-bottom: 130px;
}

.portfolio-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 59px;
    line-height: 88px;
    /* identical to box height */
    color: #000000;
    text-align: center;
    text-transform: uppercase;
}

.explore-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    /* identical to box height, or 190% */
    text-decoration-line: underline;
    color: #000000;
    text-align: center;
}

.explore-text1:hover {
    color: #000000 !important;
}

.portfolio-img-div {
    width: 515px !important;
    height: 515px !important;
    margin-top: 75px;
    /* border-radius: 10px; */
    overflow: hidden;
    background-color: black;
}

/* .portfolio-img-div .portfolio-img {
    object-fit: cover;
    transition: transform 0.5s;
    height: 100%;
}

.portfolio-img-div:hover .portfolio-img {
    transform: scale(1.12);
} */

/* .portfolio-img-div:hover,
.cursors {
    display: block !important;
} */

.portfolio-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 36px;
    /* identical to box height */
    color: #444444;
    margin-top: 18px;
}
.view-project-text-div{
    margin-top: 13px;
}
.view-svg{
    margin-left:10px;
}

.view-project-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */
text-decoration-line: underline;
margin-bottom: 2px !important;

color: #444444;
}



/* section # 4 */

.about-me-div1 {
    position: relative;
    overflow: hidden;
    background-color: white;
    z-index: 9999;
}

.about-img-div1 {
    /* background: linear-gradient(180deg, #151515 0%, #000000 100%); */
    width: 100%;
    padding: 0px;
    /* padding-top: 70px;
    padding-bottom: 70px; */
    /* display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 999;
    overflow: hidden; */
}

.about-img-div {
    position: relative;
}

.about-img-svg {
    position: absolute;
    right: 0px;
    top: 20px;
}

.years-div {
    position: absolute;
    width: 145px;
    height: 138px;
    bottom: 30px;
    left: -25px;
    display: grid;
    place-items: center;
    place-content: center;
    background: #FFFFFF;
    border-radius: 4px;
}

@media screen and (max-width: 1250px) {
    .years-div {
        left: -15px;
    }
}

.years-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    /* line-height: 50px; */
    /* identical to box height */
    color: #FAAD13;
    margin-bottom: 0px !important;
}

.years-text2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    /* line-height: 14px; */
    text-align: center;
    /* or 108% */
    color: #000000;
}

.years-text3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    /* or 108% */
    color: #FAAD13;
    display: none;
}

.years-div:hover .years-text1 {
    display: none;
    transition: all 0.4s ease-in-out;
}

.years-div:hover .years-text2 {
    display: none;
    transition: all 0.4s ease-in-out;
}

.years-div:hover .years-text3 {
    display: block;
    transition: all 0.6s ease-in-out;
}

.years-div:hover {
    background: #000000 !important;
    transition: all 0.5s ease-in-out;
}

.about-text-div1 {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 175px;

}

.about-me-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 62px;
    /* identical to box height, or 258% */
    color: #E95432;
}

.about-me-text2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 62px;
    /* identical to box height, or 138% */
    color: #151517;
}

.about-me-text3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    /* or 190% */
    color: #000000;
    margin-top: 24px;
}

.about-me-text4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    /* or 190% */
    color: #000000;
    margin-top: 30px;
}



/* section # 5 */

.design-div1 {
    padding-top: 70px;
    padding-bottom: 70px;
    background: #000000;
    overflow: hidden;
    position: relative;
    z-index: 9999;
}

.design-div2 {
    display: grid;
    place-items: center;
}

.design-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
}

.design-text2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #C6C6C6;
    margin-top: 8px;
}

.deignlogo-div {
    column-gap: 65px;
    margin-top: 35px;
}

.designlogos-text2{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 33px;
/* identical to box height */
text-align: center;
margin-top: 20px;

color: #C6C6C6;
}



@media screen and (max-width: 1199px) {
    .banner-img1 {
        width: 460px;
        height: 418px;
    }

    .about-text-div1 {
        padding-right: 80px;
    }

    .portfolio-img-div {
        width: 450px !important;
    }
}




@media screen and (max-width: 991px) {
    .home-div1 {
        padding-top: 120px;
        padding-bottom: 70px;
    }

    .banner-img-div {
        margin-top: 55px;
    }

    .work-text1 {
        text-align: center;
        font-size: 18px;
        height: 28px;
    }

    .work-text2 {
        text-align: center;
        font-size: 40px;
        line-height: 60px;
    }

    .work-company-maindiv {
        margin-top: 45px;
        row-gap: 30px;
    }

    .company-logo1 {
        width: 108px !important;
    }

    .company-logo4 {
        width: 100px !important;
    }

    .deignlogo-div {
        column-gap: 35px;
    }

    .designlogos-text2{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 33px;
/* identical to box height */
text-align: center;
margin-top: 17px;

color: #C6C6C6;
}
}


/* booking page css  */

.cal-div1 {
    min-height: 100vh;
    padding-top: 135px;
    padding-bottom: 50px;
}

.book-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 58px;
    line-height: 88px;
    /* identical to box height */
    color: #000000;
    text-align: center;
    text-transform: uppercase;
}

.book-div2 {
    margin-top: -30px;
}





@media screen and (max-width: 576px) {
    .banner-hey-text {
        text-align: center;
        line-height: 32px;
    }

    .banner-text1 {
        text-align: center;
        font-size: 34px;
        line-height: 56px;
        margin-top: 0px;
    }

    .banner-text2 {
        font-size: 41px;
        text-align: center;
        line-height: 42px;
        /* margin-top: 29px; */

    }

    .banner-text2 span {
        font-size: 50px;
        line-height: 82px;
    }

    .banner-line-svg {
        width: 150px;
    }

    .banner-para1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 200;
        font-size: 18px;
        line-height: 30px;
        /* or 167% */
        color: #EDEDED;
        margin-top: 20px;
        text-align: center;
    }

    .portfolio-div1 {
        padding-top: 70px;
        padding-bottom: 100px;
        /* background: rgba(240, 240, 240, 0.88) ; */
        background: #f1f1f1;

    }

    .portfoliopage-div1 {
        padding-top: 120px;
        padding-bottom: 110px;
        background: rgba(240, 240, 240, 0.88);
        overflow: hidden;
    }

    .portfolio-text1 {
        font-size: 42px;
        line-height: 56px;
    }

    .cal-div {
        padding-top: 120px;

    }

    .book-text {
        font-size: 42px;
        line-height: 56px;
    }

    .book-div2 {
        margin-top: 40px;
    }

    .portfolio-img-div {
        width: 325px !important;
        height: 370px !important;
        margin-top: 52px;
    }
    .view-project-text-div{
        margin-top: 0px;
    }

    .portfolio-title {
        font-size: 14px;
        line-height: 23px;
        /* margin-top: -1px; */
        /* margin-bottom: 0px !important; */
    }

    .explore-text1 {
        text-align: center;
        margin-top: 45px;
    }

    .about-img {
        width: 100% !important;
        /* height: 270px !important; */
    }

    .years-div {
        width: 110px !important;
        height: 115px !important;
        left: -35px;
    }

    .years-text1 {
        font-size: 48px;
    }

    .years-text3 {
        font-size: 13px;
        line-height: 21px;
    }

    .about-img-svg {
        right: -20px;
        top: -10px;
    }

    .about-text-div1 {
        padding-left: 22px;
        padding-right: 22px;
    }

    .about-me-text1 {
        text-align: center;
        margin-top: 45px;
    }

    .about-me-text2 {
        text-align: center;
        font-size: 44px;
        line-height: 62px;
    }

    .about-me-text3 {
        text-align: center;
        margin-top: 35px;
    }

    .about-me-text4 {
        margin-bottom: 55px;
        text-align: center;
    }

    .deignlogo-div {
        /* column-gap: 0px; */
        column-gap: 50px;
        row-gap: 30px;
        display: flex;
        justify-content: center;
    }

    .design-logo {
        width: 79px !important;
    }

    .design-text1 {
        font-size: 38px;
        line-height: 56px;
    }

    .design-text2 {
        margin-top: 20px;
    }

    .design-div1 {
        padding-top: 55px !important;
        padding-bottom: 85px !important;
    }
}



.x2-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 243px;
/* identical to box height */
line-height: 220px;
text-align: center;

background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.growths-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 65px;
/* identical to box height */
line-height: 60px;
text-align: center;
text-transform: uppercase;

background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}





@media screen and (max-width: 576px) {
    .x2-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 150px;
/* identical to box height */
line-height: 150px;
text-align: center;

background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.growths-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;
/* identical to box height */
line-height: 50px;
text-align: center;
text-transform: uppercase;

background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
}