@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
/* font-family: 'Poppins', sans-serif; */

.navbar-div1 {
    background-color: black !important;
    min-height: 95px;
    width: 100%;
    position: absolute;
    z-index: 999;
    display: flex;
    align-items: center;
}

.navbar-ul {
    margin-left: auto;
    margin-right: 150px;
}

.portfolio-logo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    /* line-height: 33px; */
    /* identical to box height */
    color: #FFFFFF !important;
    margin-left: 120px;
}

@media screen and (max-width: 1199px) {
    .navbar-ul {
        margin-right: 100px;
    }

    .portfolio-logo {
        margin-left: 75px;
    }
}


.nav-item.active .nav-link {
    color: #F74C66 !important;
}

.nav-item .nav-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200 !important;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: #DADADA !important;
    margin-left: 34px;
}

.nav-item .nav-link:hover {
    color: #F74C66 !important;
}





.mobile-navbar-div1 {
    background-color: black !important;
    min-height: 90px;
    width: 100%;
    position: absolute;
    z-index: 999;
}

@media screen and (min-width: 991px) {
    .mobile-navbar-div1 {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .navbar-div1 {
        display: none;
    }

    .mobile-navbar-div1 {
        display: block;
        position: absolute;
        width: 100%;
        /* position: fixed; */
        top: 0px;
        left: 0px;
        z-index: 1;
    }
}


.mobile-portfolio-logo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* line-height: 33px; */
    /* identical to box height */
    color: #FFFFFF !important;
    margin-left: 20px;
}

.mobile-nav-item .nav-link {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 17px !important;
    text-align: center !important;

    color: #DADADA !important;
    margin-top: 15px !important;
    margin-left: 0px !important;
}

.mobile-navbar-div2 {
    /* background: #EAEFFF; */
    /* background-color: white; */
    background: #1D1D1D;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 230px;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 89px;
    transform: translateX(-100%);
    transition: all 0.5s linear;
}

.mobile-navbar-div2.show {
    background: #1D1D1D;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 230px;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 89px;
    transition: none;
    transform: translateX(0%) !important;
    transition: all 0.5s linear;
}

.mobile-nav-item.active .nav-link {
    color: #F74C66 !important;
}

.mobile-nav-item .nav-link:hover {
    color: #F74C66 !important;
}


.dropdown-menu-div {
    background: #323232;
    box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.25);
    margin-left: 40px;
    margin-top: 7px;

}

.dropdown-menu-div1 {
    background: #323232;
    box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.25);
    margin-left: 40px;
    padding-bottom: 0px !important;
    margin-top: 7px;

}

.dropdown-item-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #787878;
}

.dropdown-item-text:hover {
    color: #DADADA;
    background-color: #323232;
}

.dropdown-divider {
    width: 80%;
    border: 1px solid #272727;
}

.dropdown-toggle::after {
    margin-left: 10px !important;
}

.dropdown-item-div1 {
    /* background: #F74C66; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 35px; */
}

.dropdown-item-div2 {
    background: #F74C66;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
}

.dropdown-item-text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #FFFFFF;
    /* background-color: #F74C66; */
}

.dropdown-item-text1:hover {
    background-color: #F74C66 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #FFFFFF;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent !important;
    color: #FFFFFF;
}




@media screen and (max-width: 576px) {
    .nav-btn2 {
        width: 90px;
        height: 55px;
        margin-right: 0px;
    }

    .dropdown-menu-div {
        background: #323232;
        box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.25);
        margin-left: 0px;
        margin-top: 7px;

    }

    .dropdown-menu-div1 {
        background: #323232;
        box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.25);
        margin-left: 0px;
        padding-bottom: 0px !important;
        margin-top: 7px;

    }

    .dropdown-item-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        /* identical to box height */
        color: #787878;
        text-align: center;
    }


    .dropdown-item-text1 {
        text-align: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        /* identical to box height */
        color: #FFFFFF;
        /* background-color: #F74C66; */
    }
}

.slide .words-wrapper {
    overflow: hidden;
   
}