@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
/* @import 'node_modules/react-modal-video/scss/modal-video.scss'; */
/* font-family: 'Poppins', sans-serif; */


.thanks-div1 {
    min-height: 100vh;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
}

.thanks-text1 {
    /* position: relative; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 130px;
    line-height: 180px;
    /* identical to box height */
    text-transform: uppercase;
    text-align: center;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.thanks-text2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */
    text-align: center;
    color: #EDEDED;
}

.thanks-text2 span a {
    color: #FFBC00 !important;
}

@media screen and (max-width: 576px) {
    .thanks-text1 {
        font-size: 50px;
        line-height: 76px;
    }

}